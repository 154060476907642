<template>
	<div class="page">
		<!--div style="height: 100px;display: flex;align-items: center;background-color: #fff;padding: 10px;margin-bottom: 20px;">
			<div style="width:200px;border-right: solid 1px #333;padding: 5px;font-size: 20px;">充电可提现金额<br/><b style="font-size:30px">{{ get_ele }}</b>元</div>
			<div style="width:200px;border-right: solid 1px #333;padding: 5px;padding-left:80px;font-size: 20px;">占用可提现金额<br/><b style="font-size:30px">{{ get_occupy }}</b>元</div>
			<div style="height:30px;padding-left: 80px;">
				<el-button type="primary" @click="apply">申请提现</el-button>
			</div>
		</div-->
		<Tables ref="xTable" :tableName="tableName" :tableData="List" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList" @clearSearch="clearSearch">

			<!--搜索自定义内容-->
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.id" size="small" clearable placeholder="结算单ID">
				</el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.status" style="width: 120px" clearable placeholder="结算状态">
					<el-option v-for="(item, index) in statusText" :label="item" :value="index" v-bind:key="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.dates" clearable type="daterange" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" start-placeholder="开始时期" end-placeholder="结束时期" size="small">
				</el-date-picker>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" type="expand" align="center" width="40px">
				<template #content="{ row }">
					<div class="expand-wrapper">
						<vxe-grid :columns="[{'field':'income','title':'营收金额'},{'field':'occupy','title':'占用金额'},{'field':'pay','title':'扣款金额'},{'field':'reset','title':'调整费用'}]" :data="row.childData"></vxe-grid>
					</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="id" title="结算单ID" align="center" />
			<vxe-table-column slot="table-item" field="group_name" title="结算周期" align="center">
				<template v-slot="{row}">
					{{ (row.btime||'')+"~"+(row.etime||'') }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="group_name" title="结算项目" align="center" />
			<vxe-table-column slot="table-item" field="income" title="充电营收" :title-help="{message: '充电营收（包括：自营订单金额、第三方订单金额、农行订单金额）'}" align="center" />
			<vxe-table-column slot="table-item" field="occupy" title="占位营收" align="center">
				<template v-slot="{row}">
					{{ Number(row.occupy) + Number(row.other_occupy) }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="outcome" title="扣款金额" :title-help="{message: '扣款金额（包含：应付电费、应付通道费、应付手续费、第三方抽成、开票税费）'}" align="center" />
			<vxe-table-column slot="table-item" field="get_occupy" title="缴费单" align="center">
				<template v-slot="{ row }">
					<el-image style="width: 100px; height: 50px" fit="contain" :src="row.url" :preview-src-list="[row.url]"></el-image>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="refund" title="调整金额" align="center">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="profit" title="可提现金额" align="center">
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="结算状态" align="center">
				<template v-slot="{row}">
					{{ statusText[row.status] }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="status" title="申请结算时间" align="center">
				<template v-slot="{row}">
					{{ row.ext["apply"+$store.state.user.userInfo.user_id] }}
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" align="center" width="220px">
				<template #default="{ row }">
					<el-button size="small" v-if="row.status == 0" plain @click="applyOne(row)">申请提现</el-button>
					<el-button size="small" plain @click="viewOne(row)">查看明细</el-button>
				</template>
			</vxe-table-column>
		</Tables>
	</div>
</template>

<script>
	import Tables from '@/components/tables'
	import upload from '@/components/upload'
	export default {
		name: 'report-group-settle',
		components: {
			Tables,upload
		},
		data() {
			return {
				// 表格信息
				tableName: '结算提现',
				isLoading: false,
				totalPage: 0,
				get_ele:0,
				get_occupy:0,
				searchFormData: {},
				List: [],
				statusText:{
					"0":"未结算",
					"1":"已申请",
					"2":"已结算",
					"3":"作废"
				},
			}
		},
		computed: {
		},
		mounted() {
			//this.getList();
		},
		activated() {},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				params = Object.assign(params, this.searchFormData);
				this.isLoading = true
				const res = await this.$api.Report.GroupShare.settlelist(params);
				this.List = res.data;
				for(let v of this.List){
					v.income = Number(v.order) + Number(v.other) + Number(v.nh) + Number(v.occupy) + Number(v.other_occupy);
					v.pipe = (v.income * 0.006).toFixed(2);
					v.outcome = Number(v.pay_ele) + Number(v.pay_other) + 10 + Number(v.pipe)/* + Number(v.pay_tax||0)*/;
					v.profit = v.income + Number(v.refund||0) - v.outcome;
					for(let u of v.percent){
						if(u.U == this.$store.state.user.userInfo.user_id){
							v.profit = (v.profit * u.P / 100).toFixed(2);
							break;
						}
					}
					v.childData = [{
						income:"自营订单金额:	" + v.order + "\n第三方订单金额:	" + v.other + "\n农行订单金额:	" + v.nh,
						occupy:"自营占位营收:	" + v.occupy + "\n第三方占位营收:	" + v.other_occupy,
						pay:"缴纳电费:	" + v.pay_ele + "\n通道费:	" + v.pipe + "\n手续费:	10.00\n第三方抽成:	" + v.pay_other/* + "\n开票税费:	" + (v.pay_tax||0)*/,
						reset:"	" + v.refund + "\n调整理由:	" + v.remark,
					},{
						income:"营收合计:	" + v.income,
						occupy:"占位合计:	" + (Number(v.occupy) + Number(v.other_occupy)).toFixed(2),
						pay:"扣款合计:	" + v.outcome.toFixed(2),
						reset:"调整合计:	" + v.refund,
					}];
					v.outcome = v.outcome.toFixed(2);
					//console.log(this.$store.state.user);
					if(v.status == 1){
						if(!v.ext["apply"+this.$store.state.user.userInfo.user_id]) v.status = 0;
						else if(v.ext["end"+this.$store.state.user.userInfo.user_id]) v.status = 2;
					}
				}
				this.get_ele = res.get_ele;
				this.get_occupy = res.get_occupy;
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
			viewOne(row){
				this.$router.push({
					name: "report-group-settleview",
					query: { group_id: row.group_id, id: row.id, btime: row.btime, etime: row.etime, min: row.ext.min, max: row.ext.max, min_other: row.ext.min_other, max_other: row.ext.max_other, min_nh: row.ext.min_nh, max_nh: row.ext.max_nh },
				});
			},
			async applyOne(row){
				//if(this.get_ele <= 0 && this.get_occupy <= 0) return;
				this.isLoading = true;
				const res = await this.$api.Report.GroupShare.settleapply({token: this.$store.state.user.token, id:row.id});
				this.isLoading = false;
				this.$refs.xTable.refreshTable();
			},
			async apply(){
				if(this.get_ele <= 0 && this.get_occupy <= 0) return;
				this.isLoading = true;
				const res = await this.$api.Report.GroupShare.settleapply({token: this.$store.state.user.token});
				this.isLoading = false;
				this.$refs.xTable.refreshTable();
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
